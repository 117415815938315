.container {
  position: fixed;
  z-index: 2147483647;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
  background: radial-gradient(50% 50%, ellipse closest-corner, rgba(0, 0, 0, 0.6) 1%, rgba(0, 0, 0, 0.8) 100%);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 24px;
  color: #272323;
}
.sub-title {
  font-size: 14px;
  color: #7b7b7b;
}
.story-title {
  font-size: 22px;
  color: #272323;
}
.submit-btn {
  background: #222;
  color: #fff;
  border: none;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  margin-top: 32px;
  cursor: pointer;
}
.sub-container {
  color: #fff;
  max-width: 400px;
  background: #fff;
  text-align: center;
  padding: 24px;
  border-radius: 5px;
  position: relative;
  height: 330px;
}
.cross-btn {
  position: absolute;
  top: -10px;
  right: -8px;
  font-size: 10px;
  width: 23px;
  height: 23px;
  border: 3px solid #fff;
  border-radius: 100%;
  background-color: #000;
  cursor: pointer;
}
.progress-text {
  color: #fff;
  width: 75%;
  word-break: break-word;
  font-size: 20px;
  margin: auto;
  margin-top: -60px;
}
