@import '../../../styles/variables.module.scss';

.coupon {
  color: #222;
  font-size: 16px;
  font-weight: 600;
}
.errrorField {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50px;
}
.input-container {
  position: relative;
  background-color: #f7f7f7;
  position: relative;
  width: 365px;
  padding: 24px;
}

.small-text {
  font-size: 13px;
  margin-top: 12px;
  text-align: left;
  opacity: 0.7;
  font-weight: 600;
  margin-bottom: -8px;
}

.available-coupons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 16px 0;
  margin: 0 12px;
}

.footer-space {
  margin-top: 90px;
}

.back-btn-container {
  display: flex;
  margin-top: -18px;
}

.antd-icon {
  color: rgb(151, 151, 151) !important;
  font-size: 22px;
  margin-right: 16px;
}

.heading-container {
  font-size: 16px !important;
  width: 274px;
}

@media (max-width: $breakpoint-xs) {
  .errrorField {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 67px;
  }
  .input-container {
    background-color: #f7f7f7;
    width: 100%;
    position: absolute;
  }

  .available-coupons-container {
    margin: 0 16px;
    padding-top: 130px;
  }

  .back-btn-container {
    width: 83%;
    justify-content: space-between;
  }

  .heading-container {
    width: 240px;
  }
}
