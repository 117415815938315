@import '../../../styles/variables.module.scss';

.textInput {
  width: 100%;
  width: 400px;
  font-size: 18px;
  font-family: Montserrat, sans-serif;
  outline: none;
  border: 1px solid #707070;
  padding: 8px;
  box-sizing: border-box;
}

// https://stackoverflow.com/a/16611160
.input-icon {
  position: relative;
}

.input-icon > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 38%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

.input-icon > input {
  padding-left: 25px;
  padding-right: 0;
}

@media (max-width: $breakpoint-md) {
  .textInput {
    width: 350px;
    font-size: 16px;
  }
}

@media (max-width: $breakpoint-sm) {
  .textInput {
    width: 290px;
    font-size: 14px;
  }
}
