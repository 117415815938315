.timer-wrap {
  margin: auto;
  color: #fff;
  font-stretch: normal;
  font-style: normal;
  font-family: Roboto;
  width: fit-content;
}
.timer-flex {
  display: flex;
  font-size: 10px;
  justify-content: space-around;
  color: #fff;
  margin: 8px auto;
}
.timer-text {
  background-color: #505156;
  border-radius: 4px;
  font-size: 30px;
  font-weight: 500;
  line-height: 0.8;
  letter-spacing: 4.1px;
  text-align: center;
  padding: 8px;
}
