@import '../styles/variables.module.scss';

.header-main {
  width: 100%;
  height: 42px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #d83532;
  color: #15213d;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

@media (max-width: $breakpoint-xs) {
  .header-main {
    font-size: 16px;
    font-weight: 600;
  }
}
