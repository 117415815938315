.custom-button {
  background-color: black;
  color: white;
  border-radius: 4px;
  font-weight: 500;
  outline: none;
  border: none;
  margin-right: 8px;
  margin-top: 8px;
  box-shadow: 1px 1px 10px #00000029;
  cursor: pointer;

  &.disabled {
    color: #bbb;
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.custom-button:active:not(.disabled) {
  background-color: #19b4c8;
}
