@import '../../../styles/variables.module.scss';

.button {
  outline: none;
  cursor: pointer;

  &.black {
    background-color: black;
    border: none;
    border-radius: 5px;
    color: white;
    padding-left: 46px;
    padding-right: 46px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  &.fix-size {
    width: 264px;
    height: 44px;
    padding: 0;
  }

  &.disabled {
    color: #bbb;
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.white {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin: 14px 0px;
  }

  &.white:active {
    background-color: #82b1ba;
    color: #fff;
  }
}
.button-text {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 16px;
}

@media (max-width: $breakpoint-sm) {
  .button {
    &.fix-size {
      width: 182px;
      height: 34px;
    }
  }
}
