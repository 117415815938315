.container {
  height: 250px;
  display: flex;
  align-items: center;
}

$grey: rgb(27, 181, 201);
$blue: rgb(19, 34, 61);

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
    border-top-color: $grey;
  }

  50% {
    transform: rotate(180deg);
    border-top-color: $blue;
  }

  100% {
    transform: rotate(360deg);
    border-top-color: $grey;
  }
}

* {
  box-sizing: border-box;
}

@mixin loaderDivMixin {
  border-radius: 50%;
  padding: 0;
  border: 3px solid transparent;
  animation: rotate linear 3.5s infinite;
}

.loader-container {
  position: relative;
  margin: auto;
  width: 80px;
  height: 80px;
  display: block;
  overflow: hidden;

  div {
    height: 100%;
  }
}

.loader,
.loader div {
  @include loaderDivMixin;

  border-top-color: $grey;
  border-left-color: $blue;
  animation-timing-function: cubic-bezier(0.55, 0.38, 0.21, 0.88);
  animation-duration: 3s;
}

.loader-container,
.loader * {
  will-change: transform;
}
