@import 'styles/variables.module.scss';
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fafafa;
}
.container-wrap {
  position: relative;
  width: 410px;
}
.container {
  position: relative;
  width: 410px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 5px;
  text-align: center;
}
.crossIconContainer {
  position: absolute;
  right: 14px;
  top: 14px;
  height: 30px;
  width: 30px;
  background-color: #a5a5a5;
  border-radius: 15px;
}
.cursor-blue-link {
  cursor: pointer;
}
.submit-bar-animated {
  position: relative;
}

.submit-bar-animated::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.7));
  transform: translateX(-40%);
  animation: progress-bar-shine 1s infinite;
}
@keyframes progress-bar-shine {
  to {
    transform: translateX(0);
    opacity: 0.1;
  }
}

.skeleton-logo {
  :global(.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph) {
    margin-top: 42px !important;
  }

  :global(.ant-skeleton-title) {
    display: none;
  }
  :global(.ant-skeleton-paragraph) > li {
    width: 80% !important;
    margin: 3% 12%;
    border-radius: 4px;
  }
  :global(.ant-skeleton-paragraph) {
    li:nth-child(2) {
      width: 50% !important;
      margin: 0% 25%;
      border-radius: 4px;
    }
  }
  :global(.ant-skeleton-paragraph) {
    li:nth-child(3) {
      display: none;
    }
  }
}

.moreDetailsContainer {
  width: 410px;
  height: 510px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 5px;
  text-align: center;
  max-width: 100%;
  .message {
    margin-top: 40px;
  }
}
.crossIcon {
  fill: white;
  margin: 7px;
}
.signUpContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 410px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 5px;
  text-align: center;
  animation-name: slidingSignUp;
  animation-duration: 1s;
  transition-timing-function: ease-out;
}

.resetPassCont {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 410px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 5px;
  text-align: center;
  animation-name: slidingSignUp;
  animation-duration: 1s;
  transition-timing-function: ease-out;
}

@keyframes slidingSignUp {
  from {
    right: -410px;
  }
  to {
    right: 0px;
  }
}

.hideContainer {
  visibility: hidden;
}

.reset {
  position: absolute;
  right: 20px;
  top: 160px;
  text-decoration: underline;
  font: normal normal 400 14px/33px Montserrat;
}

.logo {
  margin-top: 40px;
  width: 158px;
  height: 72px;
  object-fit: contain;
}

.message {
  letter-spacing: 0px;
  font: normal normal 600 18px/33px Montserrat;
  color: #272323;
  margin: 14px auto 0px;
}

.longMessage {
  font: normal normal normal 12px/33px Montserrat;
  letter-spacing: 0px;
  color: #7b7b7b;
  margin: 8px auto 0px;
  line-height: 20px;
  margin-bottom: 10px;
}

.signUpAndLogin {
  display: flex;
  justify-content: center;
  margin: 0px auto 0px;
}
.spanSignUpAndLogin {
  border-bottom: 2px solid #000000;
  text-align: center;
  font: normal normal 700 16px/33px Montserrat;
}

.userInput {
  margin: auto 13px;
}

.inputFieldContainer {
  display: flex;
  width: 370px;
  height: 48px;
  box-shadow: 0px 2px 6px #0000001f;
  border-radius: 5px;
  max-width: 100%;
}

.inputField {
  border: none;
  outline: none;
  font: normal normal normal 16px/33px Montserrat;
  letter-spacing: 0px;
  color: #a5a5a5;
  width: 80%;
}

.forgotPassCont {
  margin: 16px auto 0px;
  width: 370px;
  display: flex;
  justify-content: space-between;
}

.forgotPass {
  text-decoration: underline;
}

.checkBoxContainer {
  display: flex;
  justify-content: space-between;
}
.checkBox {
  margin-right: 8px;
  height: 18px;
  width: 18px;
  border: 1px solid #a5a5a5;
  border-radius: 4px;
}

.continueWithContainer {
  display: flex;
  width: 370px;
  max-width: 95%;
  justify-content: space-between;
}

.continueWith {
  text-align: center;
  font: normal normal 500 14px/33px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  white-space: nowrap;
  padding: 0px 3px;
}

.horizontalLine {
  width: 108px;
  margin: auto 0px auto;
}
.otherLogin {
  margin: 15px auto 50px;
  display: flex;
  justify-content: space-evenly;
}

.googleFacebookApple {
  margin: auto;
}
.disableDeafult {
  color: #757575;
}

.dontHaveAccount {
  margin-top: 33px;
  font: normal normal 400 14px/33px Montserrat;
  span {
    text-decoration: underline;
    font: normal normal 600 14px/33px Montserrat;
  }
}
.TC {
  text-align: left;
  max-width: 370px;
  margin: 27px auto 0px;
}

.verifyOTP {
  width: 334px;
  height: 29px;
  text-align: center;
  font: normal normal 600 24px/33px Montserrat;
  letter-spacing: 0px;
  color: #272323;
  margin: 50px auto 0px;
}

.userMobile {
  width: 334px;
  height: 18px;
  text-align: center;
  font: normal normal normal 14px/33px Montserrat;
  letter-spacing: 0px;
  color: #7b7b7b;
  margin: 16px auto 0px;
}

.inputOTP {
  width: 260px;
  height: 33px;
  display: flex;
  justify-content: space-evenly;
  margin: 48px auto 0px;
  input {
    height: 33px;
    width: 54px;
    outline: none;
    border: none;
    border-bottom: 2px solid black;
    text-align: center;
    font: normal normal 400 24px/16px Montserrat;
  }
}

.loginViaPass {
  width: 156px;
  height: 19px;
  margin: 14px auto 0px;
  text-align: center;
  font: normal normal 500 16px/33px Montserrat;
  letter-spacing: 0px;
  color: #000000;
}

.OtpTimer {
  margin: 35px auto 0px;
  text-align: center;
  font: normal normal 400 14px/33px Montserrat;
  margin: 35px auto 0px;
  color: #a5a5a5;
  span {
    text-decoration: underline;
    color: #000000;
  }
}

.submitBtn {
  margin: 0px auto 0px;
  width: 272px;
  height: 48px;
}

.loginPageSetting {
  margin: 20px 20px 0px;
  font: normal normal 500 18px/16px Noto Sans;
  letter-spacing: 0px;
  color: #292929;
}
.loginSection {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 12px;
}

.primaryLogin {
  display: flex;
  margin: 20px 20px 0px;
  width: 795px;
  height: 52px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
}

.collectUserData {
  margin: 20px 20px 0px;
  width: 795px;
  height: 40px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  // display: flex;
  button {
    margin: 10px 0px;
  }
  :global(.ant-switch-checked) {
    background: #30b73b !important;
    box-shadow: inset 0px 2px 8px #00000033 !important;
  }
}

.saveAndDiscard {
  display: flex;
  width: 318px;
  justify-content: space-between;
  margin: 30px 20px 20px;
}

.saveChanges {
  width: 150px !important;
  height: 50px !important;
  background: #0353e9 !important;
  border-radius: 5px !important;
  font: normal normal 600 14px/19px Noto Sans !important;
  color: white !important;
  :hover {
    color: white !important;
  }
}

.discardChanges {
  width: 150px !important;
  height: 50px !important;
  background: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 5px !important;
  font: normal normal 600 14px/19px Noto Sans !important;
  color: black !important;
  :hover {
    color: black !important;
  }
}

.collectUserDataHeading {
  font: normal normal 500 14px/6px Noto Sans;
  letter-spacing: 0px;
  color: #2655a7;
  margin: 16px 20px;
}

.toggles {
  width: 589px;
  height: 26px;
  margin: auto auto;
  display: flex;
  justify-content: space-between;
}

.loginOptions {
  height: 30px;
  display: flex;
  margin: auto 0px;
  :global(.ant-switch-checked) {
    background: #30b73b !important;
    box-shadow: inset 0px 2px 8px #00000033 !important;
  }
}

.marginRight {
  margin-right: 10px;
}

.radioBtnGroup {
  span {
    font: normal normal 500 14px/6px Noto Sans;
    letter-spacing: 0px;
    color: #323b4b;
  }
  :global(.ant-radio-wrapper) {
    :nth-child(2) {
      font: normal normal 600 12px/17px Noto Sans !important;
      letter-spacing: 0.29px !important;
      color: #606060 !important;
    }
  }

  :global(.ant-radio-inner::after) {
    background-color: black !important;
  }
  :global(.ant-radio-inner) {
    border-color: black !important;
  }
}

.secondaryLogin {
  display: block;
  background: #eaeaea;
  border: 1px solid #a0a0a0;
  border-radius: 2px;
  font: normal normal 500 14px/6px Noto Sans;
  letter-spacing: 0px;
  color: #323b4b;
  width: 104px;
  height: 31px;
  position: relative;
}

.inputCustomisationField {
  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  font: normal normal 500 14px/19px Noto Sans;
  letter-spacing: 0px;
  color: #cccccc;
  margin-right: 6px;
  padding: 6px;
  outline: none;
  &:hover {
    border: 1px solid #0353e9;
  }
}

.colorPickerCont {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 10px 36px 0 rgb(0 0 0 / 16%), 0 0 0 1px rgb(0 0 0 / 6%);
  input {
    opacity: 0;
    height: 100%;
    width: 100%;
  }
}

.inputCustomisationCont {
  width: 628px;
  display: flex;
  margin-top: 30px;
}

.title {
  font: normal normal 500 14px/22px Noto Sans;
  letter-spacing: 0px;
  color: #606060;
  margin: 0px 10px 0px 28px;
  width: 143px;
  padding-top: 8px;
}

.inputAndColor {
  display: flex;
  margin-left: 9px;
}

.loginFlowCont {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 12px;
  margin-top: 10px;
}
.customizeViewHeading {
  font: normal normal 500 18px/16px Noto Sans;
  letter-spacing: 0px;
  color: #292929;
  margin: 24px 0px 0px 20px;
}
.customizeView {
  border-bottom: 1px solid #cccccc;
  width: 100%;
  margin: 30px 0px;
  padding-left: 30px;
  span {
    font: normal normal 500 16px/16px Noto Sans;
    letter-spacing: 0px;
    color: #292929;
    height: 35px;
    width: 142px;
    display: inline-block;
    position: relative;
    top: 2px;
    text-align: center;
  }
}

.saveAndRestore {
  display: flex;
  margin: 50px 0px 20px;
  .saveChanges {
    width: 152px;
    height: 50px;
    background: #0353e9;
    border-radius: 5px;
    text-align: center;
    font: normal normal 600 14px/19px Noto Sans;
    letter-spacing: 0.34px;
    color: #ffffff;
    border: none;
    margin-left: 33px;
  }

  .restoreChanges {
    width: 152px;
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    text-align: center;
    font: normal normal 600 14px/19px Noto Sans;
    letter-spacing: 0.34px;
    color: #080808;
    border: none;
    background: white;
    img {
      width: 15px;
      height: 15px;
      margin: auto;
    }
  }
}

.uploadLogo {
  font: normal normal 500 14px/6px Noto Sans;
  letter-spacing: 0px;
  color: #2655a7;
  margin: auto 20px auto;
}

.OTPContainer {
  width: 380px;
  height: 380px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: auto;
}

.previewAndSettings {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #cccccc;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cccccc;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cccccc;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cccccc;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cccccc;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #cccccc;
}
.wrap-secondary_login_container {
  margin-left: 64px;
}
.wrap-secondary_login_margin {
  margin: 4px 0px 4px 120px;
}
.verify-login-subheading {
  font: normal normal medium 14px/19px Noto Sans;
  letter-spacing: 0px;
  color: #cccccc;
  margin-left: 20px;
}
@media (max-width: $breakpoint-xs) {
  .container-wrap {
    width: 100%;
  }
  .container {
    width: 100%;
  }
  .inputFieldContainer {
    width: 95%;
  }
  .forgotPassCont {
    width: 95%;
  }

  .OTPContainer {
    width: 100%;
  }
  .userMobile {
    width: 100%;
  }
}
.wrap-secondary_login {
  display: flex;
  align-items: center;
}
