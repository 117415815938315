@import 'styles/variables.module.scss';

.main-container {
  width: 100%;
  margin: auto;
}

.root {
  position: relative;
  // box-shadow: 1px 3px 5px 0 rgba(51, 49, 49, 0.33);
  background-color: #fff;
  text-align: center;
  padding: 9px 10px;
  width: 430px;
  margin: auto;
  border-radius: 10px;
  font-family: Lato, sans-serif;

  &.embedded {
    max-width: 100%;
    box-shadow: none;
    padding: 0;
  }

  &.no-padding {
    padding: 0;
  }
}

.rootFullPageBlock {
  @extend .root;
  width: 100%;
  max-width: 1300px;
  max-height: 80%;
  display: flex;
  justify-content: center;
}
.rootPopUpPageBlock {
  @extend .root;
  width: 90%;
  max-width: 1300px;
  box-shadow: none;
  background-color: transparent !important;
}

.rootTransition {
  @extend .root;
  animation: zoomin ease 0.3s;
}

.rootTransitionFade {
  @extend .root;
  animation: fadeOut linear 0.3s;
}

.backdrop {
  z-index: -10001;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  width: 100%;
  opacity: 0.75;
}
.backdropForInarticle {
  @extend .backdrop;
  background-color: transparent;
}

.backdropTransition {
  @extend .backdrop;

  background-color: #000;
  animation: zoomin ease 0.3s;
  overflow: hidden;
}

.backdropTransitionFade {
  @extend .backdrop;

  background-color: #000;
  animation: fadeOut linear 0.3s;
}

@keyframes zoomin {
  @for $i from 0 through 75 {
    #{$i * 1%} {
      opacity: calc($i/100);
    }
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0.75;
  }

  100% {
    opacity: 0;
  }
}

.cross-icon-container {
  text-align: end;
  cursor: pointer;
  position: absolute;
  right: -8px;
  top: -8px;
}
.cross-icon {
  color: #fff !important;
  font-size: 10px;
  width: 23px;
  height: 23px;
  border: 3px solid #fff;
  border-radius: 100%;
  background-color: #000;
  padding-top: 3px;
}

@media (max-width: $breakpoint-paywall) {
  .root {
    width: 90%;
    padding: 10px 8px;
  }

  .wrap-label {
    max-width: 290px;
  }

  .google-btn {
    height: 34px;
  }
}

.fullPageWapper {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 16px 8px;
}

.popUpWapper {
  background-color: transparent !important;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
