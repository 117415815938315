@import 'styles/variables.module.scss';

.root.minimized {
  display: none;
}

.container {
  position: absolute;
  z-index: 10003;
  width: 100%;
  opacity: 1;
  height: 100%;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

@media (max-height: 550px) {
  .flex-container {
    align-items: baseline;
    margin-top: 32px;
  }
}
