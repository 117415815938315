.checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 50px;
  stroke-miterlimit: 10;
  stroke: #19b4c8;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  transform: scale(1.7);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 5% auto;
  box-shadow: inset 0 0 0 #19b4c8;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 30px #7ac142;
  }
}

.container {
  padding: 0;
  margin-top: -4px;
  margin-right: -20px;
  margin-left: 10px;
  font-family: Roboto, sans-serif;
}

.large-text {
  font-size: 28px;
  letter-spacing: 0.02px;
  font-weight: 700;
  line-height: 1.2;
}

.middle-text {
  font-size: 24px;
  font-weight: 700;
}

.small-text {
  font-size: 16px;
  margin-bottom: 8px;
}
