.main-container {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/Bg-grid-gradiant.svg);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-container {
  width: 35%;
  height: 328px;
  background: #ffffff;
  box-shadow: 0px 10px 30px #00000015;
  border-radius: 10px;
  margin: 26px;
  padding-left: 42px;
}

.field-container {
  display: flex;
  flex-direction: column;
  padding-top: 35px;
}
.head-box {
  text-align: left;
  font-family: 'Assistant', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 46px;
  user-select: none;
}

.input-box {
  width: 90%;
  height: 40px;
  border: 1px solid #f8f7ff !important;
  border-radius: 8px;
  padding-left: 16px;
  background: #e8eaec;
}

.input-box:focus {
  border: 1px solid #bbb5ff !important;
  outline: none;
}

.password-box {
  position: absolute;
  color: #1d2943 !important;
  width: 100%;
  height: 37px;
  border-radius: 8px;
  border: none !important;
  padding-left: 16px;
  background: #e8eaec;
}

.password-box:focus {
  border: 1px solid #bbb5ff !important;
  outline: none;
}
.inputContainer {
  display: flex;
  position: relative;
  color: #1d2943 !important;
  border-radius: 8px;
  background: #e8eaec;
  width: 90%;
  height: 40px;
  border: 1px solid #f8f7ff !important;
}
.icon-container {
  position: relative;
  left: 92%;
  top: 22%;
}
.icon {
  height: 22px;
}

.login-btn {
  width: 90%;
  height: 43px;
  background: #1d2943;
  border-radius: 8px;
  margin-top: 26px;
  cursor: pointer;
  border: none;
}

.login-txt {
  text-align: center;
  color: #ffffff;
  font-family: 'Assistant', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  user-select: none;
}

.Register-txt {
  text-align: center;
  color: #1d2943;
  font-family: 'Assistant', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  // cursor: pointer;
}

@media (max-width: 760px) {
  .main-container {
    width: 100%;
    height: 100vh;
    background-image: url(../../assets/mobileLoginbg.png);
    background-size: cover;
  }

  .login-container {
    width: 94%;
    padding-left: 33px;
  }
}

@media (min-width: 760px) and (max-width: 1085px) {
  .login-container {
    width: 63%;
  }
}

@media (min-width: 1085px) {
  .login-container {
    width: 35%;
    max-width: 700px;
  }
}
