.container {
  padding-top: 100px;
  font-family: Roboto, sans-serif;
}

.large-text {
  font-size: 28px;
  letter-spacing: 0.02px;
  font-weight: 700;
  margin-top: 36px;
}

.middle-text {
  font-size: 18px;
  font-weight: 700;
  margin-top: 24px;
}

.small-text {
  font-size: 18px;
  margin-bottom: 8px;
  margin-top: -16px;
}

.progress-container {
  margin: 100px auto 0 auto;
  width: 100%;
  text-align: center;
}

.progress {
  background: #bae9ef;
  border-radius: 100px;
}

.progress-bar {
  height: 8px;
  border-radius: 4px;
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-striped .progress-bar {
  background-color: #bae9ef;
  width: 100%;
  background-image: linear-gradient(to right, #36dbe6, #19b4c8);
  animation: progressAnimationStrike 2s;
}

@keyframes progressAnimationStrike {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
