.rounded-input-text {
  display: flex;
  height: 44px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
}

.input-text {
  display: flex;
  background: #fff;
  border-bottom: 1px solid #15213d;
}

.otp-box {
  border: none;
  padding: 0 8px 0 8px;
  letter-spacing: 23px;
  border-radius: 6px;
  outline: none;
  font-size: 24px;
  width: 100%;
}

.input-field-common {
  color: #262626;
  border: none;
  padding: 0 8px 0 8px;
  letter-spacing: 0.5px;
  border-radius: 6px;
  outline: none;
  font-size: 16px;
  width: 100%;
}
