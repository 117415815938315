.progress-container {
  width: 100%;
  margin-top: 33px;
}

.progress {
  display: flex;
  overflow: hidden;
  height: 48px;
  border-radius: 6px;
}

.progress-bar {
  overflow: hidden;
  text-align: center;
  border-radius: inherit;
  background-color: #15213d;
  border-radius: 68px;
  width: 100%;
  padding-top: 7px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.progress-bar-animated {
  position: relative;
}

.progress-bar-animated::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.7));
  transform: translateX(-100%);
  animation: progress-bar-shine 1s;
}

@keyframes progress-bar-shine {
  to {
    transform: translateX(0);
    opacity: 0.1;
  }
}
