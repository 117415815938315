@import '../../../styles/variables.module.scss';

.transition-container {
  height: 450px;
  text-align: center;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.screen-view {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
}

.logo {
  width: 90%;
  max-width: 370px;
}

.conscent-circle {
  font-size: 22px;
  font-weight: bold;
  max-width: 238px;
  height: 238px;
  background: #fff;
  box-shadow: 1px 1px 6px #cbe3e6;
  opacity: 1;
  border-radius: 100%;
  margin: 50px auto;
  padding-top: 104px;
}

@media (max-width: $breakpoint-sm) {
  .conscent-circle {
    width: 200px;
    height: 200px;
    padding-top: 80px;
  }
}
