@import '../../../styles/variables.module.scss';

.text {
  &.bold {
    font-family: Montserrat, sans-serif;
    font-weight: 900;
  }

  font-size: 8px;
  line-height: 20px;
  font-family: Montserrat, sans-serif;

  &.title {
    font-family: Montserrat, sans-serif;
    font-weight: 900;
    font-size: 20px;
  }

  &.mar-b-50 {
    margin-bottom: 50px;
  }
}

.bold {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
}

.email-text {
  font-size: 16px;
  padding: 0 10px;
  color: #72a3aa;
  font-weight: bold;
  word-break: break-all;
}

.heading {
  font-size: 25px;
  letter-spacing: 0.02px;
  font-weight: bold;
  line-height: 1.2;
  font-family: Roboto, sans-serif;

  &.no-bold {
    font-weight: normal;
  }
}

.heading-small {
  font-size: 24px;
  letter-spacing: 0.02px;
  font-weight: bold;
  line-height: 1.2;
  font-family: Roboto, sans-serif;
}

.midHeading {
  font-size: 18px;
  letter-spacing: 0.02px;
  font-weight: bold;
  line-height: 1.2;
  font-family: Roboto, sans-serif;
}

.subHeading {
  font-size: 15px;
  letter-spacing: 0.02px;
  font-family: 'Montserrat', sans-serif;
  color: #5d5d5d;
}

.subtext {
  font-size: 15px;
}

.title {
  font-size: 11px;
  font-weight: bold;
  margin-right: 172px;
}

.button {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 20px;
}

.subscription-title-large {
  width: 420px;
  margin: auto;
  word-break: break-word;
  font-size: 15px;
}

@media (max-width: $breakpoint-sm) {
  .heading {
    font-size: 13px;
  }

  .heading-small {
    font-size: 18px;
  }

  .email-text {
    font-size: 12px;
  }

  .subHeading {
    font-size: 12px;
  }

  .button {
    font-size: 15px;
  }

  .subtext {
    font-size: 12px;
  }

  .midHeading {
    font-size: 10px;
  }

  .subscription-title-large {
    width: 300px;
    font-size: 13px;
  }
}
