.bar {
  width: 120px;
  border: 1px solid #cbe3e6;
  height: 6px;
  background-color: #cbe3e6;
  box-shadow: 1px 1px 6px #00000029;
  border-radius: 10px;
  margin-left: 6px;
}

.bar-filled {
  color: #82b1ba;
  background-color: #82b1ba;
}
