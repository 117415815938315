@import 'styles/variables.module.scss';

.container {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  color: #222;
  background-color: #fff;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.16);
  border-radius: 11px;
}

.content {
  display: flex;
  justify-content: center;
  border: solid 1px #c9c9c9;
  border-top: none;
  border-radius: 11px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.content-one {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  padding: 24px 0 16px 0;
}

.subscription-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}

.left-col {
  width: 50%;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.heading-wrap {
  height: auto;
  line-height: 1.4;
}

.heading-wrap-one {
  @extend .heading-wrap;

  width: 50%;
  text-align: left;
}

.content-btn {
  width: 50%;
}

.right-col {
  width: 50%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 30px;
}

.right-col-one-item {
  width: 50%;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.middle-col {
  display: contents;
}

.mobile-vertical-line {
  display: none;
}

.heading {
  font-size: 22px;
  font-weight: 600;
  margin-top: 12px;
}

.heading-one {
  font-size: 36px;
  font-weight: 600;
}

.small-sub-heading {
  font-size: 14px;
  color: #222;
  font-weight: bold;
}

.middle-sub-heading {
  color: #222;
  font-weight: normal;
}

.small-sub-heading-one {
  font-size: 16px;
  color: #222;
  font-weight: bold;
  margin-top: 40px;
  letter-spacing: 1px;
}

.small-heading-one {
  @extend .small-sub-heading-one;

  margin-top: 0;
  padding-bottom: 24px;
}

.sub-heading {
  font-size: 18px;
}

.vertical-line {
  border-right: 1.2px dashed lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
}

.or-write {
  background-color: #fff;
  width: 20px;
  height: 25px;
  position: relative;
  left: 10px;
  font-size: 16px;
  font-weight: 600;
  top: -4px;
}

.subscription-title {
  font-size: 16px;
  font-weight: 600;
  word-break: break-word;
  line-height: 1.5;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscription-title-one {
  font-size: 27px;
  font-weight: 600;
  text-align: left;
  line-height: 1.3;
}

.subscription-text-one {
  @extend .subscription-title-one;

  font-size: 19px;
  font-weight: normal;
  margin-top: 16px;
}

.subscription-title-container {
  width: 60%;
  padding-right: 20px;
}

.subscription-title-margin {
  @extend .subscription-title;
}

.subscription-text {
  font-size: 16px;
  word-break: break-word;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  color: #15213d;
  margin-bottom: 8px;
}

.pass-text {
  font-size: 17px;
  word-break: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #15213d;
  margin-bottom: -12px;
  height: 45px;
  line-height: 1.3;
}

.pass-text-one {
  @extend .pass-text;

  justify-content: left;
  font-weight: 600;
  font-size: 30px;
  height: auto;
}

.pass-text-container {
  text-align: left;
  width: 50%;
}

.coupon-exposed {
  color: #15213d;
  margin-top: 8px;
}

.pass-coupon-exposed {
  color: #15213d;
  margin-top: 20px;
  margin-bottom: -8px;
}

.subscription-heading {
  margin-bottom: 8px;
  font-size: 17;
  font-family: Lato;
  color: 222;
}

.subscription-heading-one {
  @extend .subscription-heading;

  text-align: left;
}

.pass-container {
  width: 312px;
  margin: auto;
}

.subscription-container-one {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
  align-items: center;
}

.coupon-title {
  font-size: 14px;
  margin-top: 12px;
}

.coupon-title-one {
  font-size: 16px;
  margin-top: 12px;
}

.or {
  margin: 4px 0 20px 0;
}

.powered-by {
  margin-bottom: 16px;
  font-size: 10px;
  font-family: Montserrat, sans-serif;
  align-self: flex-end;
}

.powered-by-text {
  margin-bottom: 16px;
  font-size: 12px;
  opacity: 0.8;
}

.powered-by-black-text {
  font-size: 12px;
  opacity: 0.8;
}

.box {
  width: 100%;
  height: 65px;
}

.box-top-shadow {
  width: 100%;
  height: 65px;
  box-shadow: 0px -50px 36px -1px rgba(255, 255, 255, 0.9) inset;
  position: absolute;
}

.box-bottom-shadow {
  width: 100%;
  height: 65px;
  box-shadow: 0px 60px 36px -1px rgba(255, 255, 255, 0.9) inset;
  position: absolute;
  z-index: -1;
}

.only-one-content {
  border: solid 1px #c9c9c9;
  border-top: none;
  border-radius: 11px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

@media (max-width: $breakpoint-paywall) {
  .heading {
    margin-top: 8px;
    margin-bottom: -8px;
  }

  .subscription-heading {
    margin-bottom: -10px;
  }

  .subscription-heading-one {
    text-align: center;
  }

  .container {
    max-width: 420px;
    margin: 0 auto -2px auto;
  }

  .content {
    display: block;
    padding-bottom: 16px;
  }

  .left-col {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 0;
  }

  .right-col {
    width: 100%;
    padding: 16px 8px 0 8px;
  }

  .right-col-one-item {
    width: 100%;
    padding: 16px 19px;
  }

  .vertical-line {
    border-right: none;
    border-bottom: 1.2px dashed lightgrey;
    margin: 0;
  }

  .or-write {
    width: 50px;
    left: 0;
    top: 12px;
  }

  .subscription-title {
    height: auto;
  }

  .subscription-text {
    margin-top: 8px;
  }

  .subscription-title-margin {
    margin-top: 20px;
  }

  .content-one {
    padding-bottom: 36px;
    padding-top: 24px;
  }

  .heading-wrap-one {
    width: 100%;
    text-align: center;
    height: auto;
  }

  .heading-one {
    margin-top: 12px;
    font-size: 30px;
  }

  .pass-text-container {
    text-align: center;
    width: 100%;
  }

  .pass-text {
    margin-bottom: -16px;
    margin-top: -8px;
  }

  .pass-text-one {
    margin-top: 32px;
    margin-bottom: 12px;
    font-size: 26px;
  }

  .small-sub-heading-one {
    margin-top: 20px;
    letter-spacing: 0;
  }

  .pass-container {
    width: 100%;
  }

  .middle-col-margin {
    margin: 0 8px;
  }

  .mobile-vertical-line {
    display: block;
    margin-top: -16px;
  }

  .coupon-title-one {
    font-size: 14px;
    width: 266px;
    margin: auto;
    margin-top: 16px;
  }

  .subscription-title-container {
    width: 100%;
    padding-right: 0;
  }

  .subscription-title-one {
    font-size: 25px;
    text-align: center;
  }

  .subscription-text-one {
    font-size: 19px;
  }
}
