@import 'styles/variables.module.scss';
.add-info-header {
  text-align: left;
  color: #13223f;
  width: max-content;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
}

.add-info-sub-text {
  text-align: left;
  color: #556073;
  width: max-content;
  font-size: 16px;
  margin-bottom: 53px;
}

.inp-border-with-error {
  border: 1px solid #ea1800;
}
.mandatory-field {
  font-size: 9px;
  float: right;
  margin: 0px 0px 10px 0px;
}

.add-info-input {
  display: block;
  width: 100%;
  height: 44px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 16px;
}
.wrapper-for-name {
  position: relative;
  box-shadow: 1px 3px 5px 0 rgb(51 49 49 / 33%);
  background-color: #fff;
  text-align: center;
  padding: 9px 10px;
  width: 366px;
  margin: auto;
  border-radius: 10px;
  font-family: Lato, sans-serif;
}
.add-info-submit {
  margin-top: 36px;
  width: 100%;
  height: 48px;
  background: #13223f 0% 0% no-repeat padding-box;
  border-radius: 54px;
  outline: none;
  color: white;
  border: #13223f;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

.add-info-skip {
  margin-top: 19px;
  font-size: 13px;
  color: #13223f;
  cursor: pointer;
}
.input-padding {
  padding-left: 33px;
}
.padding-for-cc {
  padding-left: 50px;
}

.input-container {
  font-size: 17px;
  margin-top: 21px;
  img {
    margin: -72px 298px 0px 0px;
    padding: 0px 6px 0px 8px;
  }
  span {
    position: absolute;
    top: 2px;
    height: 40px;
    left: 0px;
    border-right: 1px solid #f7f7f7;
    padding: 8px 8px;
  }
}
.add-info-container {
  padding: 20px 20px 0px 20px;
  font-family: 'Roboto';
}

.error-msg {
  color: red;
  font-size: 12px;
}
.position-relative {
  position: relative;
}
@media (max-width: 380px) {
  .add-info-sub-text {
    font-size: 12px;
  }
}
@media (max-width: $breakpoint-sm) {
  .add-info-container {
    padding: 20px 20px 0px 18px;
  }
}
@media (max-width: 350px) {
  .cross-icon-container {
    left: 291px;
  }
  .container {
    width: 300px;
  }
}
